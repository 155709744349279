.wrapper {
  text-align: center;
  margin-left:10px;
 
  
  
}
.right-container {
    width:auto;
    height:100vh;
    overflow-x:hidden;
    /* max-width:95%; */
    
    padding-top:0px;
    overflow-y: scroll;

    }

.centered-p-in-div {
  text-align:center; 
  vertical-align: middle;
  display: table-cell; 
  padding-left:4px;
}

.logo-container{width:95%;text-align: center;padding-top: 10px;}
.sideMenu-container{width:auto;max-width:95%;min-width:95%;padding-left:5%;clear: both;padding-top: 20px;}
.std-logo-container{width:90px;margin-left:50px;margin-right:20px}

.publicationBox {
  max-width: 250px;
  max-height: 185px;
  min-width:  250px;
  min-height: 185px;
  border: solid darkgrey 0.75px;
  padding: 15px 5px 15px 5px;
  margin: 10px;
}
.welcome-screen{
  text-align : left;
  margin-top: 5%;
  margin-left: 15%;
  margin-right:15%;
}
.entry-block{ 
  text-align: left;
  border-color: "darkgrey";
  border-width: 0.3px;
  border-style: solid;
  position: relative;
  padding: 2px 2px 2px 2px;
  width: 90%;
  min-height: 35px;
}

.hover {
  background-color: #E4DED4;
  border: solid darkgrey 1px;
}

.background-video{

  height: auto;
  width: 100%;
  padding-left:10px;
  padding-right:10px;
  padding-top:30px;
  position: relative; /* optional depending on what you want to do in your app */
  
  
  }

.omnibar-search-item{
  margin:"3px";
  background-color: #efefef;
  cursor: pointer;
}
.omnibar-search-item:hover{
    background-color: #e0e0e0;
}


.tooltip-div {
  margin:"8px";
  padding:"13px"
}
